import React from 'react'
import { withMainLayout } from 'components/layouts'
import css from './style.module.scss'
import cn from 'classnames'
import { Link } from 'gatsby'
import SEO from 'components/seo'

export default withMainLayout(PublicOfferPage)

function PublicOfferPage() {
  return (
    <div className={cn(css.page, 'container')}>
      <SEO
        title='Публичная оферта'
      />
      <p align="center">
        <strong>ПУБЛИЧНАЯ ОФЕРТА</strong>
      </p>
      
      <p align="justify">
        Настоящая публичная Оферта регулирует отношения по использованию Сайта между МБФ «ОР АВНЕР» (Адрес местонахождения: 127055, г. Москва, ул. Новосущевская, д. 15, ИНН: 7701166751, ОГРН: 1027739515650), именуемым в дальнейшем «Фонд», и лицом, использующим Сайт для перечисления Пожертвования, именуемым в дальнейшем «Благотворитель».
      </p>
           
      <p align="center">
        <strong>1. ОСНОВНЫЕ ТЕРМИНЫ</strong> 
      </p>
      <p align="justify">
        <strong>1.1.</strong> Для целей применения и толкования настоящей публичной Оферты используются определенные ниже основные термины (если в Оферте прямо не указано иное). В тексте Оферты эти термины могут быть указаны с большой или маленькой буквы в единственном или множественном числе, а также в виде сокращений.
      </p>
      
      <ul>
        <li>
          <p align="justify">
            <strong>Сайт <Link to='/'>{ process.env.DOMAIN }</Link></strong> (далее – «Сайт») – интернет-ресурс, состоящий из совокупности графических и информационных материалов, а также программ для ЭВМ, баз данных и размещенной в них информации. Сайт содержится в информационной системе, обеспечивающей доступность указанной информации в сети Интернет по адресу (доменному имени): { process.env.DOMAIN }, включая все уровни указанного домена.
          </p>
        </li>
        <li>
          <p align="justify">
            <strong>Фонд</strong> – МБФ «ОР АВНЕР» (Адрес местонахождения: 127055, г. Москва, ул. Новосущевская, д. 15, ИНН: 7701166751, ОГРН: 1027739515650), а также его уполномоченные представители. Фонд является некоммерческой организацией, созданной в соответствии с Федеральным законом от 12.01.1996 N 7-ФЗ «О некоммерческих организациях», Федеральным законом от 11.08.1995 N 135-ФЗ «О благотворительной деятельности и добровольчестве (волонтерстве)».
          </p>
        </li>
        <li>
          <p align="justify">
            <strong>Публичная оферта</strong> (далее – «Оферта») – настоящий документ, являющийся предложением Фонда заключить Договор на перечисление Пожертвования (далее – «Договор») на условиях, содержащихся в нем (п. 2 ст. 437 Гражданского кодекса РФ).
          </p>
        </li>
        <li>
          <p align="justify">
            <strong>Благотворитель</strong> – любое физическое или юридическое лицо, принявшее Оферту.
          </p>
        </li>
        <li>
          <p align="justify">
            <strong>Пожертвование</strong> – денежные средства, перечисляемые Благотворителем в адрес Фонда. Пожертвование является благотворительным пожертвованием. Пожертвование является благотворительным пожертвованием в общеполезных целях на уставную деятельность Фонда.
          </p>
        </li>
        <li>
          <p align="justify">
            <strong>Стороны</strong> – Фонд и Благотворитель.
          </p>
        </li>
      </ul>

      <p align="center">
        <strong>2. ОБЩИЕ ПОЛОЖЕНИЯ</strong>
      </p>
          
      <p align="justify">
        <strong>2.1.</strong> Оферта вступает в силу со дня её размещения на Сайте и действует до момента её отзыва Фондом. Фонд вправе отозвать Оферту в любое время.
      </p>
      <p align="justify">
        <strong>2.2.</strong> Оферта может быть изменена Фондом в одностороннем порядке без какого-либо специального уведомления Благотворителя и любого иного посетителя Сайта. Новая редакция Оферты вступает в силу с момента ее размещения на Сайте. Актуальная редакция Оферты размещена в сети Интернет по следующему адресу: <Link to='/public-offer'>https://{ process.env.DOMAIN }/public-offer</Link>
      </p>
      <p align="justify">
        <strong>2.3.</strong> Перечисление Пожертвования Благотворителем в соответствии с условиями настоящей Оферты является её полным и безусловным акцептом. Датой заключения Договора является дата поступления Пожертвования на счет Фонда. Договор считается заключенным в письменной форме в соответствии с п. 3 ст. 434 Гражданского кодекса РФ.
      </p>
      <p align="justify">
        <strong>2.4.</strong> Акцептируя Оферту, Благотворитель соглашается с условиями Политики обработки персональных данных, размещенной в сети Интернет по следующему адресу: <Link to='/privacy-policy'>https://{ process.env.DOMAIN }/privacy-policy/</Link>. В соответствии с п. 5 ч. 1 ст. 6 Федерального закона от 27 июля 2006 года № 152-ФЗ «О персональных данных» обработка Фондом Персональных данных Благотворителя в целях исполнения Договора не требует получения отдельного согласия Благотворителя.
      </p>
      <p align="justify">
        <strong>2.5.</strong> Благотворитель-физическое лицо гарантирует, что достиг возраста 18-лет, соответствует иным требованиям действующего законодательства страны, где Благотворитель-физическое лицо является резидентом, для заключения Договора на условиях настоящей Оферты, а также обладает полными юридическими полномочиями (правоспособностью и дееспособностью) для принятия и выполнения юридических обязательств.
      </p>
      <p align="justify">
        <strong>2.6.</strong> Благотворитель-юридическое лицо, гарантирует, что обладает полными юридическими полномочиями для принятия и выполнения юридических обязательств.
      </p>

      <p align="center">
        <strong>3. ПРЕДМЕТ ДОГОВОРА</strong>
      </p>
      <p align="justify">
        <strong>3.1.</strong> Предметом Договора является перечисление Благотворителем Пожертвования в собственность Фонда. Перечисляя Пожертвование, Благотворитель подтверждает, что передает Фонду собственные денежные средства (Благотворитель вправе единолично распоряжаться денежными средствами и/или получил необходимые согласия и разрешения) и делает это добровольно, бескорыстно, на безвозмездной основе. Сумма Пожертвования НДС не облагается, если иное не предусмотрено применимым законодательством.
      </p>
      <p align="justify">
        <strong>3.2.</strong> Выполнение Жертвователем действий по настоящему договору является пожертвованием в соответствии со статьей 582 Гражданского кодекса РФ.
      </p>
      <p align="justify">
        <strong>3.3.</strong> Фонд использует полученное от Благотворителя Пожертвование для достижения целей, содержащихся в п. 2.1. Устава Фонда, утвержденном 23.05.1997 г. (с последующими изменениями и дополнениями), (далее – «Устав»), а именно развитие и сохранение еврейской культуры, языка и традиций, а также осуществление благотворительной деятельности в соответствии с законодательством Российской Федерации. Перечисляя Пожертвование, Благотворитель соглашается, что оно будет использовано в соответствии с указанными целями.
      </p>
      <p align="justify">
        <strong>3.4.</strong> Перечисление Благотворителем Пожертвования осуществляется с помощью использования платёжных систем (сервисов), доступных на Сайте. Перечисляя Пожертвование, Благотворитель соглашается с правилами соответствующих платежных систем (сервисов), в том числе с условиями о комиссии.  
      </p>
      <p align="justify">
        <strong>3.5.</strong> Документом, подтверждающим перечисление Пожертвования, является любое сообщение, направляемое Фондом и/или платежной системой (сервисом) на контактные данные, сообщенные Благотворителем, содержащее информацию о Пожертвовании. 
      </p>

      <p align="center">
        <strong>4. ПРАВА И ОБЯЗАННОСТИ СТОРОН</strong>
      </p>
      <p align="justify">
        <strong>4.1.</strong> Благотворитель имеет право по своему усмотрению выбрать размер и периодичность Пожертвования, перечисляемый в адрес Фонда. 
      </p>
      <p align="justify">
        <strong>4.2.</strong> Фонд использует не менее 80% от полученного Пожертвования на достижение целей, указанных в Уставе (п. 3.2. настоящего Договора). Не более 20% от Полученного Пожертвования Фонд использует для оплаты труда административно-управленческого персонала, иных организационных и административных расходов. Фонд самостоятельно определяет срок и порядок использования Пожертвования.
      </p>
      <p align="justify">
        <strong>4.3.</strong> Благотворитель имеет право знакомиться с ежегодными отчетами Фонда, размещаемыми в открытом доступе. 
      </p>
      <p align="justify">
        <strong>4.4.</strong> После перечисления Пожертвования Благотворителем, возврат денежных средств Фондом не осуществляется. 
      </p>
      <p align="justify">
        <strong>4.5.</strong> Фонд вправе направлять Благотворителю информационную, рекламную и иную рассылку на адрес электронной почты, на номер телефона, указанные Благотворителем.
      </p>

      <p align="center">
        <strong>5. РАЗРЕШЕНИЕ СПОРОВ</strong>
      </p>


      <p align="justify">
        <strong>5.1.</strong> В случае возникновения любых разногласий или споров между Сторонами настоящего Соглашения обязательным условием до обращения в суд является предъявление претензии (письменного предложения о добровольном урегулировании спора).
      </p>
      <p align="justify">
        <strong>5.2.</strong> Получатель претензии в течение 30 (тридцати) календарных дней со дня ее получения, письменно уведомляет заявителя претензии о результатах рассмотрения претензии.
      </p>
      <p align="justify">
        <strong>5.3.</strong> При невозможности разрешить спор в добровольном порядке, любая из Сторон вправе обратиться в суд за защитой нарушенных прав.
      </p>
      <p align="justify">
        <strong>5.4.</strong> Оферта толкуется в соответствии с законодательством Российской Федерации. Споры рассматриваются в соответствии с действующим законодательством Российской Федерации в Арбитражном суде, суде общей юрисдикции, мировом суде по месту нахождения Фонда.
      </p>
          
    </div>
  )
}
